export enum FilterType {
  TAGS = 'tags',
  LOCATION = 'entryLocation',
  TARGET_GROUP = 'eventTargetGroup',
  COURSE_TYPE = 'eventCourseType',
  SPEAKERS = 'eventSpeakers',
  DATE = 'entryDate',
  BRANCH_TYPE = 'officeBranchType',
  TREATMENT_ROOMS_FROM = 'officeTreatmentRoomsFrom',
  TREATMENT_ROOMS_TILL = 'officeTreatmentRoomsTill',
  ORDER_BY = 'orderBy',
}

const detailedFilterTypes = [
  FilterType.DATE,
  FilterType.LOCATION,
  FilterType.TARGET_GROUP,
  FilterType.SPEAKERS,
  FilterType.BRANCH_TYPE,
  FilterType.TREATMENT_ROOMS_FROM,
  FilterType.TREATMENT_ROOMS_TILL,
];

const advancedFilterTypes = [...detailedFilterTypes, FilterType.COURSE_TYPE];

export function getHasAdvancedFilter(filterTypes: Array<FilterType>): boolean {
  return filterTypes.some((cur) => advancedFilterTypes.includes(cur));
}

export function getHasDetailedFilter(filterTypes: Array<FilterType>): boolean {
  return filterTypes.some((cur) => detailedFilterTypes.includes(cur));
}
